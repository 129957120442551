import React from 'react'
import './Hero.css'

import unityLogo from '../../../images/unityLogo2.png';
import cLogo from '../../../images/cLogo.png';

const Hero = () => {
  return (
    <section className='hero-container' id='hero'>
      <div className='hero-content'>
        <h2>A Game Developer <br />
      {/* | QA Enginner */}
        </h2>
<br />
        <p>Passionate for Technology  |  Consistently Learning  |  Detail Oriented | Team Player  </p>
        <br />
        <p>Unity and C# Game Developer, also experienced in JavaScript, Node.js, React and more</p>
        <br />

        <p>Looking for my first <span style={{fontWeight:"600"}}>Home</span>  in the indusrty to contribute my skills and passion</p>

      </div>

      <div className='hero-img'>
        
        <div>
<div className='left-tech'>
<div className='tech-icon'>
<img src={unityLogo} alt="Unity Logo" />
        </div>

        <div className='tech-icon'>
<img src={cLogo} alt="c# Logo" />
        </div>
<div className='tech-icon'>
          <img src="../../../images/reactLogo.png" alt="" />
        </div>

{/* <div className='tech-icon'>
          <img src="../../../images/mongodb-logo.png" alt="" />
        </div> */}
</div>


        <img src="../../../images/charc2.png" alt=""  />
        </div>

<div>

<div className='tech-icon'>
          <img src="../../../images/jslogo.png" alt="" />
        </div>
        <div className='tech-icon'>
          <img src="../../../images/nodejs-logo.png" alt="" />
        </div>
<div className='tech-icon'>
          <img src="../../../images/html5logo.png" alt="" />
        </div>
<div className='tech-icon'>
          <img src="../../../images/cssLogo.png" alt="" />
        </div>


</div>
      </div>
    </section>
  )
}


export default Hero
