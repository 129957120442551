import React from 'react'
import './ProjectCard.css'



const ProjectCard = ({details}) => {
  return (
    <div className='portfolio-project-card'>
      <div className='project-card-title'>
        <h6>{details.title}</h6>
        <div className='card-desc'>
    {details.description}
</div>
      </div>

<div className='portfolio-duration'>

<a href={details.link} target="_blank"><img src={details.icon}  /> </a>

</div>


    </div>
  )
}

export default ProjectCard